const BASE_URL = process.env.PREPR_CUSTOMER_API_BASE_URL

function endpoint(endpoint) {
  return `${BASE_URL}${endpoint}`
}

export const getTokenEndpoint = endpoint("persons/sign_in")
export const getMagicLinkTokenEndpoint = endpoint("persons/sign_in_with_magic")
export const updateMeEndpoint = endpoint(
  "persons/me?fields=emails,date_of_birth,gender,phones,addresses,tags"
)
export const registerCustomerEndpoint = endpoint("persons")
export const verificationEmailEndpoint = endpoint("persons/request_sign_in")
export const requestForgotPasswordEndpoint = endpoint("persons/request_sign_in")
export const personsEndpoint = endpoint(`persons/me`)
export const meEndpoint = endpoint(
  "persons/me?fields=emails,date_of_birth,gender,phones,addresses,tags"
)
