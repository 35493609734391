import React, { createContext, useState, useEffect } from "react"
import { useOAuthTokens } from "src/hooks/useOAuthTokens"
import {
  transformRequest,
  transformResponse,
  fromDateFormat,
} from "../services/preprApi"

import { updateMeEndpoint, meEndpoint } from "../services/preprCustomerApi"

import { get, post } from "axios"
import { navigate } from "gatsby"
import { homeRoute } from "src/routes/routes"
import usePreferences from "../hooks/usePreferences"
import { useAccount } from "../hooks/useAccount"

const AuthenticatedApiContext = createContext({})
const { Provider } = AuthenticatedApiContext

export function AuthenticatedApiProvider({ children }) {
  const { accessToken, forgetOAuthTokens } = useOAuthTokens()
  const { me, setMeAccount } = useAccount({})
  const [headers, setHeaders] = useState(undefined)
  const isLoading = !accessToken || !headers
  const { dateFormat } = usePreferences()

  useEffect(
    function redirectToLogin() {
      if (!accessToken) {
        navigate(homeRoute)
      }
    },
    [accessToken]
  )

  useEffect(
    function setAuthorizationHeaders() {
      setHeaders({ Authorization: `Bearer ${accessToken}` })
    },
    [accessToken]
  )

  useEffect(
    function fetchProfile() {
      if (accessToken && headers) {
        getMe().then(me => setMeAccount(me))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [headers]
  )

  function transformMeResponse(me) {
    return transformResponse({
      ...me,
      email: me.emails ? me.emails.items[0].email : "",
      phone: me.phones
        ? me.phones.items.length > 0
          ? me.phones.items[0].phone
          : ""
        : "",
      dateOfBirth: me.date_of_birth
        ? fromDateFormat(me.date_of_birth).toFormat(dateFormat)
        : "",
    })
  }

  async function getMe() {
    try {
      const response = await get(meEndpoint, { headers })
      return transformMeResponse(response.data)
    } catch (e) {
      forgetOAuthTokens()
    }
  }

  async function updateMe(values) {
    const data = transformRequest(values)
    const response = await post(updateMeEndpoint, data, { headers })
    setMeAccount(transformMeResponse(response.data))

    return response
  }

  return (
    <Provider
      value={{
        me,
        updateMe,
      }}
    >
      {children}
    </Provider>
  )
}

export default AuthenticatedApiContext
